// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-dev-404-page-js": () => import("./../../dev-404-page.js" /* webpackChunkName: "component---cache-dev-404-page-js" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-customer-info-jsx": () => import("./../../../src/pages/customer-info.jsx" /* webpackChunkName: "component---src-pages-customer-info-jsx" */),
  "component---src-pages-device-info-error-jsx": () => import("./../../../src/pages/device-info-error.jsx" /* webpackChunkName: "component---src-pages-device-info-error-jsx" */),
  "component---src-pages-device-info-jsx": () => import("./../../../src/pages/device-info.jsx" /* webpackChunkName: "component---src-pages-device-info-jsx" */),
  "component---src-pages-disclaimer-jsx": () => import("./../../../src/pages/disclaimer.jsx" /* webpackChunkName: "component---src-pages-disclaimer-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-maintenance-jsx": () => import("./../../../src/pages/maintenance.jsx" /* webpackChunkName: "component---src-pages-maintenance-jsx" */),
  "component---src-pages-order-failed-jsx": () => import("./../../../src/pages/order-failed.jsx" /* webpackChunkName: "component---src-pages-order-failed-jsx" */),
  "component---src-pages-order-success-jsx": () => import("./../../../src/pages/order-success.jsx" /* webpackChunkName: "component---src-pages-order-success-jsx" */),
  "component---src-pages-order-summary-jsx": () => import("./../../../src/pages/order-summary.jsx" /* webpackChunkName: "component---src-pages-order-summary-jsx" */),
  "component---src-pages-page-not-found-jsx": () => import("./../../../src/pages/page-not-found.jsx" /* webpackChunkName: "component---src-pages-page-not-found-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-product-info-jsx": () => import("./../../../src/pages/product-info.jsx" /* webpackChunkName: "component---src-pages-product-info-jsx" */),
  "component---src-pages-tos-jsx": () => import("./../../../src/pages/tos.jsx" /* webpackChunkName: "component---src-pages-tos-jsx" */),
  "component---src-pages-ua-error-jsx": () => import("./../../../src/pages/ua-error.jsx" /* webpackChunkName: "component---src-pages-ua-error-jsx" */)
}

